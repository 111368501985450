// @ts-nocheck

import '../App.css';
import Gallery from './Gallery';
import Techstack from './Techstack';
import { useEffect, useRef, useState } from 'react';

const images = [
    '/placeholder/1.png',
    '/placeholder/2.png',
    '/placeholder/3.png',
    '/placeholder/4.png',
    '/placeholder/5.png',
    '/placeholder/6.png',
    '/placeholder/7.png',
    '/placeholder/8.png',
    '/placeholder/9.png',
];


function Main() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((currentImageIndex) => (currentImageIndex + 1) % images.length);
        }, 1200);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <>
            <div className="h-[90vh] sm:h-[90vh] w-full">
                <div className='flex flex-col justify-between h-full'>
                    <div className=''>
                        <div className='mx-4 sm:w-3/5 sm:mx-auto'>
                            <p className='text-center leading-[1.1] sm:leading-[1.1] text-2xl sm:text-3xl pt-12 sm:pt-8'>
                                <span className='ital py-2 px-1'> a </span>
                                <span className='yellow py-2 px-1'> SOFTWARE DEV</span>
                                <span className='ital py-2 px-1'>with the </span>
                                <span className='ital py-2 px-1'>hint of </span>
                                <span className='yellow py-2 px-1'> DESIGN </span>
                                <span className='ital py-2 px-1'>who loves to </span>
                                <span className='yellow py-2 px-1'>TRAVEL </span>
                                <span className='ital py-2 px-1'>and make </span>
                                <span className='yellow py-2 px-1'>FILMS.</span>
                            </p>
                            <p className='text-center mt-8 text-xl'>
                                <span className='text-font'>My </span>
                                <span className='yellow shadow'> spirit </span>
                                <span className='text-font'> animal is </span>
                                <span className='text-font px-1'> 🦈 </span>
                            </p>
                        {/* <div className="pt-10 flex gap-4 items-center">
                            <a className="text-md" target="_blank" href="https://www.linkedin.com/in/anmolbhardwaj/"><i className="bi bi-linkedin"></i></a>
                            <a className="text-md" target="_blank" href="https://github.com/anmolbhardwaj17"><i class="bi bi-github"></i></a>
                            <a className="text-md" target="_blank" href="https://twitter.com/anmolbhardwajj"><i class="bi bi-twitter"></i></a>
                            <a className="text-md" target="_blank" href="https://www.behance.net/anmolbhardwaj"><i class="bi bi-behance"></i></a>
                            <a className="text-md" target="_blank" href="https://placeholder.anmolbhardwaj.in/"><i class="bi bi-camera-fill"></i></a>
                        </div> */}
                        </div>

                    </div>
                    <div className="text-center mt-auto mx-auto">
                        <img className="sm:w-4/5 mx-auto" src="assets/anmol.png" alt="Description" />
                    </div>
                </div>
            </div>
            <div className="my-20">
                <div className="text-center flex justify-center items-center gap-8">
                    <div className='flex gap-4'>
                        <img className='rotate' src="assets/1.svg" alt="Description" width="16px" />
                        <img className='rotate' src="assets/2.svg" alt="Description" width="24px" />
                    </div>
                    <div className='relative'>
                    <p className="text-xl">EXPERIENCE</p>
                    <div class="absolute right-0"><img className='' src="assets/stroke.svg" alt="Description" width="100px" /></div>
                    </div>
                    <div className='flex gap-4'>
                        <img className='rotate' src="assets/3.svg" alt="Description" width="24px" />
                        <img className='rotate' src="assets/4.svg" alt="Description" width="16px" />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row">
                    <div className="w-full px-4 sm:px-0 sm:w-4/5 my-20 mx-auto">
                        <div className='flex flex-wrap justify-center items-center'>
                            <div className="w-full sm:w-1/3 md:w-1/4 px-4 py-8">
                                <div className="flex">
                                    <div >
                                        <img className='' src="assets/siemba.svg" alt="Description" width="48px" />
                                    </div>
                                    <div className="pl-4">
                                        <p className="text-md font-light">Siemba.io</p>
                                        <p className="text-font text-sm pb-2">Software Developer</p>
                                        <p className="text-font text-xs text-gray-500">Feb 2023 - Present</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/3 md:w-1/4 px-4 py-8">
                                <div className="flex">
                                    <div >
                                        <img className='' src="assets/gravystack.svg" alt="Description" width="48px" />
                                    </div>
                                    <div className="pl-4">
                                        <p className="text-md font-light">Gravystack</p>
                                        <p className="text-font text-sm pb-2">Software Developer</p>
                                        <p className="text-font text-xs text-gray-500">May 2022 - Feb 2023</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/3 md:w-1/4 px-4 py-8">
                                <div className="flex">
                                    <div >
                                        <img className='' src="assets/samsung.svg" alt="Description" width="48px" />
                                    </div>
                                    <div className="pl-4">
                                        <p className="text-md font-light">Samsung RnD</p>
                                        <p className="text-font text-sm pb-2">RnD Intern</p>
                                        <p className="text-font text-xs text-gray-500">Nov 2021 - May 2022</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/3 md:w-1/4 px-4 py-8">
                                <div className="flex">
                                    <div >
                                        <img className='' src="assets/tedx.svg" alt="Description" width="48px" />
                                    </div>
                                    <div className="pl-4">
                                        <p className="text-md font-light">TEDxVITVellore</p>
                                        <p className="text-font text-sm pb-2">Director of Tech</p>
                                        <p className="text-font text-xs text-gray-500">Jan 2022 - Mar-2023</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/3 md:w-1/4 px-4 py-8">
                                <div className="flex">
                                    <div >
                                        <img className='' src="assets/gamepoint.svg" alt="Description" width="48px" />
                                    </div>
                                    <div className="pl-4">
                                        <p className="text-md font-light">Gamepoint</p>
                                        <p className="text-font text-sm pb-2">Web Development Intern</p>
                                        <p className="text-font text-xs text-gray-500">Sep 2021 - Nov 2021</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/3 md:w-1/4 px-4 py-8">
                                <div className="flex">
                                    <div >
                                        <img className='' src="assets/acmvit.svg" alt="Description" width="48px" />
                                    </div>
                                    <div className="pl-4">
                                        <p className="text-md font-light">ACM-VIT</p>
                                        <p className="text-font text-sm pb-2">Project Guide</p>
                                        <p className="text-font text-xs text-gray-500">Apr 2021 - Mar 2022</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/3 md:w-1/4 px-4 py-8">
                                <div className="flex">
                                    <div >
                                        <img className='' src="assets/safeouts.svg" alt="Description" width="48px" />
                                    </div>
                                    <div className="pl-4">
                                        <p className="text-md font-light">Safeouts</p>
                                        <p className="text-font text-sm pb-2">Web Development Intern</p>
                                        <p className="text-font text-xs text-gray-500">Nov 2020 - Jan 2021</p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full sm:w-1/3 md:w-1/4 px-4 py-8">
                                <div className="flex">
                                    <div >
                                        <img className='' src="assets/alfaleus.svg" alt="Description" width="48px" />
                                    </div>
                                    <div className="pl-4">
                                        <p className="text-md font-light">Alfaleus Tech</p>
                                        <p className="text-font text-sm pb-2">Unity Development Intern</p>
                                        <p className="text-font text-xs text-gray-500">Jan 2020 - Mar 2020</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div>
                    <div className='text-center py-10'>
                        <div className='relative py-2'>
                        <p className='text-xl px-1'>MY TOOLS</p>
                        <div class="absolute left-1/2"><img className='' src="assets/stroke.svg" alt="Description" width="100px" /></div>
                        </div>
                        <Techstack />
                    </div>
                </div>
            </div>



            <div className="h-[100vh] flex flex-col sm:flex-row">
                <div
                    className="flex-1 border border-gray-900 mx-auto flex justify-center items-center relative"
                    style={{
                        background: `url('/assets/newspaper.png') no-repeat bottom center`,
                        backgroundSize: 'contain',
                        overflow: 'hidden',
                    }}
                >
                    <div className='w-3/5 gradient-border' style={{ maxWidth: '100%', height: 'auto' }}>
                        <img
                            src={images[currentImageIndex]}
                            alt="Dynamic Content"
                            className=''
                            style={{
                                boxShadow: '0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.08), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.12), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.15), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.18), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.22), 0px 100px 80px 0px rgba(0, 0, 0, 0.30)',
                            }}
                        />
                    </div>
                    <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center" style={{ maxWidth: '100%', maxHeight: '100%' }}>
                        <img
                            src="/assets/museum.svg"
                            alt="Top Image"
                            className=" h-auto w-4/5 z-10"
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                    </div>
                </div>
                <div className="flex-1 border border-gray-900 px-auto pt-10 flex justify-center items-center">
                    <div className='text-center w-full px-10 sm:px-0 sm:w-4/5'>
                        <p className='text-font text-sm pb-6'>SINCE 2022</p>
                        <p className='text-xl'>PLACEHOLDER.RAW</p>
                        <p className='text-font text-sm p-2'>I wish eyes could take pictures.</p>
                        <button className='mt-10 dark-button'>
                            <a target="_blank" href="https://www.instagram.com/placeholder.raw/"><div className='flex justify-center align-center'><img className='mr-3' src="techstack/camera.svg" alt="Description" width="16px" /><p>SEE MORE</p></div></a>
                        </button>
                    </div>
                </div>
            </div>
            <div className="h-[100vh] flex flex-col sm:flex-row">

                <div className="flex-1 border border-gray-900 px-auto pt-10 flex justify-center items-center order-last sm:order-none">
                    <div className='text-center w-full px-10 sm:px-0 sm:w-4/5'>
                        <p className='text-font text-sm pb-4'>SINCE 2019</p>
                        <div className='flex justify-center items-center'>
                            <img src='/assets/bank.svg'
                                alt="Dynamic Content" />
                        </div>
                        {/* <p className='text-font text-sm pt-8'>Creative minds are rarely tidy.</p> */}
                        <p className='text-font text-sm pt-3'>ERROR is by far the most interesting part of design process.</p>
                        <button className='mt-10 dark-button'>
                            <a target="_blank" href="https://www.figma.com/@anmolbhardwaj"><div className='flex justify-center align-center'><img className='mr-3' src="techstack/figma.svg" alt="Description" width="16px" /><p>SEE MORE</p></div></a>
                        </button>
                    </div>
                </div>
                <div
                    className="flex-1 border border-gray-900 px-auto py-10 flex justify-center items-center light-bg order-first sm:order-none"
                >
                    <div className='w-3/5'>
                        <img
                            src='/assets/paper.png'
                            alt="Dynamic Content"
                            className=''
                            style={{
                                boxShadow: '0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.08), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.12), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.15), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.18), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.22), 0px 100px 80px 0px rgba(0, 0, 0, 0.30)',
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className='my-10 py-10 relative'>
                <div className='relative pb-3'>
                <p className='text-2xl text-center'>I have KOINOPHOBIA</p>
                </div>
                <p className='text-font text-sm text-center yellow'>Better an oops than a what if.</p>
                <Gallery />
                <div className="flex justify-center items-center mt-4 mb-10">
                    <img
                        src="/assets/exp1-straight.svg"
                        className="sm:hidden"
                        width="220px"
                    />
                </div>
                <div className='text-gray-700'>
                    <p className="text-font text-center">#PrayForMyBankAccount</p>
                </div>
                <div className="absolute top-10 right-0 w-30 sm:w-100">
                    <img
                        src="/assets/exp1.svg"
                        alt="Top Image"
                        className="hidden h-auto w-30 sm:w-100 z-10 sm:block"
                    />
                </div>
            </div>

            <div className="h-screen flex flex-col sm:flex-row">
                <div className="flex-1 border border-gray-900 flex justify-center items-center p-4 text-center"
                    style={{ backgroundImage: `url('/assets/watch.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                >IF OPPORTUNITY DOESN'T KNOCK. <br />
                    BUILD A DOOR.</div>
                <div className="flex-1 border border-gray-900 pl-10 pt-10 flex items-center">
                    <div>
                        <p className='py-3 text-lg'>BUILD THE EXTRAORDINARY.</p>
                        <p className='text-font text-sm'>Time for some coffee with Focaccia in Goa 📍</p>
                        <p className='text-font text-sm pt-8'>Lets stay in touch</p>
                        <p className='pb-4'><a href="mailto: anmolbhardwaj17@gmail.com">hey@anmolbhardwaj.in</a></p>
                        <div className="py-4 flex gap-4 items-center">
                            <a className="text-xl " target="_blank" href="https://www.linkedin.com/in/anmolbhardwaj/"><i className="bi bi-linkedin"></i></a>
                            <a className="text-xl" target="_blank" href="https://github.com/anmolbhardwaj17"><i class="bi bi-github"></i></a>
                            <a className="text-xl" target="_blank" href="https://twitter.com/anmolbhardwajj"><i class="bi bi-twitter"></i></a>
                            <a className="text-xl" target="_blank" href="https://www.behance.net/anmolbhardwaj"><i class="bi bi-behance"></i></a>
                            <a className="text-xl" target="_blank" href="https://placeholder.anmolbhardwaj.in/"><i class="bi bi-camera-fill"></i></a>
                            <a className='text-font' href="https://bento.me/anmolbhardwaj" target="_blank" rel="noopener noreferrer">
                                Bento
                            </a>

                            {/* <a className="social-icon" target="_blank" href="https://www.figma.com/@anmolbhardwaj"><i class="fab fa-figma"></i></a>             */}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p className='py-4 text-xs text-gray-500 text-font text-center'>ANMOL BHARDWAJ 2024</p>
            </div>
        </>
    );
}

export default Main;
