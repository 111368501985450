import React, { useRef, useEffect } from 'react';

const Gallery = () => {
    const scrollRef = useRef(null);

    useEffect(() => {
        const scrollAmount = 1;
        const scrollInterval = setInterval(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollLeft += scrollAmount;
                if (scrollRef.current.scrollLeft >= scrollRef.current.scrollWidth / 2) {
                    scrollRef.current.scrollLeft = 0;
                }
            }
        }, 10);

        return () => clearInterval(scrollInterval);
    }, []);

    return (
        <div ref={scrollRef} className="gallery overflow-hidden whitespace-nowrap max-w-full">
            <div className="image-container my-20 flex flex-nowrap">
                {[...Array(2)].map((_, i) => (
                    <React.Fragment key={i}>
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/1.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/2.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/3.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/4.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/5.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/6.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/7.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/8.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/9.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/10.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/11.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/12.png" alt="Description" />
                        <img className='w-2/5 max-w-xs md:w-1/6 object-contain p-1' src="gallery/13.png" alt="Description" />
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
