import React, { useRef, useEffect } from 'react';

const Techstack = () => {
    const techRef = useRef(null);

    useEffect(() => {
        const scrollAmount = 1;
        const scrollInterval = setInterval(() => {
            if (techRef.current) {
                techRef.current.scrollLeft += scrollAmount;
                if (techRef.current.scrollLeft >= techRef.current.scrollWidth / 2) {
                    techRef.current.scrollLeft = 0;
                }
            }
        }, 20);

        return () => clearInterval(scrollInterval);
    }, []);

    return (
        <div ref={techRef} className="gallery overflow-hidden whitespace-nowrap max-w-full">
            <div className="image-container my-20 flex flex-nowrap">
                {[...Array(2)].map((_, i) => (
                    <React.Fragment key={i}>
                        <img className='grey-icon mx-8' src="techstack/nodejs.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/mongodb.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/nestjs.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/angular.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/reactjs.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/go.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/python.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/java.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/premiere.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/figma.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/swift.svg" alt="Description" width="64px"/>
                        <img className='grey-icon mx-8' src="techstack/aws.svg" alt="Description" width="64px"/>
                        
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Techstack;
