// Loader.js

import React from 'react';

const Loader = () => {
  return (
<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
  <div className='flex items-center flex-col text-center'>
    <div className="loader"></div>
    <p className="mt-4 shadow">Loading</p>
  </div>
</div>
  );
};

export default Loader;
