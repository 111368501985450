
import { useRef, useState } from 'react';
import '../App.css';

function Nav() {

  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(null);

  const toggleAudio = () => {
    const audio = audioRef.current;
    if (audio) {
        if (audio.paused) {
          setIsPlaying(true)
            audio.play();
        } else {
          setIsPlaying(false)
            audio.pause();
        }
    }
};
  
  return (
    <nav className="py-6 sticky top-0 z-20 flex items-center justify-between bg-black p-4">
      <div className="flex-1">
        {/* <div className='flex'>
        <p className='linear-wipe cursor-pointer' onClick={toggleAudio}>
        <i className="bi bi-speaker-fill"></i> <span className='text-font pl-1 hidden sm:inline-flex'>{isPlaying ? 'Stop' : 'Enhance'}</span>
        </p>
        </div> */}

      </div>
      
      <div className="flex-2 sm:flex-1 flex justify-center">
      ANMOL BHARDWAJ
      </div>
      {/* <audio ref={audioRef} src="/audio/ohuwent.mp3" /> */}
      
      <div className="flex-1 flex justify-end">
      <div className='flex gap-4 hidden sm:inline-flex'>
      <a className='text-font' href="https://bento.me/anmolbhardwaj" target="_blank" rel="noopener noreferrer">
            Bento
        </a>
      <a href="https://github.com/anmolbhardwaj17" target="_blank" rel="noopener noreferrer">
            <img className='' src="techstack/github.svg" alt="Description" width="24px"/>
        </a>

        {/* <a href="https://www.linkedin.com/in/anmolbhardwaj/" target="_blank" rel="noopener noreferrer">
            <img className='' src="techstack/linkedin.svg" alt="Description" width="24px"/>
        </a>
        <a href="https://placeholder.anmolbhardwaj.in/" target="_blank" rel="noopener noreferrer">
            <img className='' src="techstack/camera.svg" alt="Description" width="24px"/>
        </a> */}
      </div>
      </div>
    </nav>
    // <div className='py-6 sticky top-0 z-10 bg-black text-center z-20'>ANMOL BHARDWAJ</div>
  );
}

export default Nav;
 