import logo from './logo.svg';
import './App.css';
import Nav from './components/Nav';
import Main from './components/Main';
// @ts-ignore
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loader from './components/Loader';
import { useEffect, useState } from 'react';
import FontFaceObserver from 'fontfaceobserver';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const font3 = new FontFaceObserver('Druk-Italic');
    const font1 = new FontFaceObserver('Gilroy');
    const font2 = new FontFaceObserver('GTAlpina');
    

    Promise.all([
      font3.load(),
      font1.load(),
      font2.load()
     
    ]).then(() => {
      const images = document.querySelectorAll('img');
      const imagePromises = Array.from(images).map((image) => {
        return new Promise((resolve) => {
          image.onload = resolve;
        });
      });

      Promise.all(imagePromises).then(() => {
        setLoading(false);
      });
    });
  }, []);



  return (
    <>
    {loading ? <Loader /> :
     <>
     <BrowserRouter>
     <Nav />
       <Routes>
         <Route path="/" element={<Main />} />
       </Routes>
     </BrowserRouter>
       
       
     </>
    }
    </>
   
  );
}

export default App;
